.video{
    left:50%; top:50%; height: 100%;
    transform: translate(-50%, -50%);  object-fit: cover;
    width: 100%;
    /* z-index: -2;  */
    position: absolute;
    background: "" ;
    /* url("https://irp.cdn-website.com/a08a0621/dms3rep/multi/Clown+Gif.gif"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
  }